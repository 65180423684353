import React from "react"
import { LayoutWhite } from "@layout"
import { HeroHomepageWhiteScreen } from "@components/hero"
import SEO from "@components/seo"
import {
  MainBlockTitleUnderline,
  MainBlockATMInstantCash,
  MainBlockHowToStartWithInstantCash,
  MainBlockTrackYourSpending,
  MainBlockWavyWhite,
  MainBlockHowATMMembersAreUsingInstantCash,
  MainBlockPersonalFinanceScreens,
  MainBlockRewards,
  MainBlockTestimonials,
  MainBlockPersonalFinance,
  MainBlockFAQs,
  MainBlockJoinColony,
  MainBlockJoinMillionsDownload,
} from "@components/main-blocks"

const utmParams =
  "?utm_source=atm.com&utm_medium=website&utm_campaign=web_atm_get_started"
const ctaUrl = `https://app.atm.com/auth/register${utmParams}`

const Homepage = () => (
  <LayoutWhite
    backgroundImage="animated"
    hero={<HeroHomepageWhiteScreen ctaUrl={ctaUrl} />}
    ctaUrl={ctaUrl}
    utmParams={utmParams}
  >
    <SEO
      overrideTitleTemplate={`%s`}
      title="ATM Instant Cash™ & Rewards - Earn, Budget, Save & Access Cash*"
      description="Discover how ATM.com helps you earn extra cash, manage your finances, and access instant cash advances when you need them most. Join the Ant Colony and start earning rewards, budgeting smarter, and accessing emergency funds today."
      image="/img/og/og-atm-1.png"
    />
    <MainBlockTitleUnderline className="off-white" />
    <MainBlockATMInstantCash />
    <MainBlockRewards />
    <MainBlockPersonalFinanceScreens />
    <MainBlockHowToStartWithInstantCash />
    <MainBlockWavyWhite />
    <MainBlockHowATMMembersAreUsingInstantCash />
    <MainBlockTrackYourSpending />
    <MainBlockPersonalFinance />
    <MainBlockJoinMillionsDownload ctaUrl={ctaUrl} />
    <MainBlockFAQs />
    <MainBlockTestimonials />
    <MainBlockJoinColony platform="both" ctaUrl={ctaUrl} />
  </LayoutWhite>
)

export default Homepage
